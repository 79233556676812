import { IonButton, IonIcon } from '@ionic/react'
import { useTenantConfig } from '../lib/providers/tenantConfig'
import { css } from '@emotion/css'
import * as icons from 'ionicons/icons'

export const SocialMedia: React.FC = () => {
  const tenantConfig = useTenantConfig()

  const links: { icon: string; href?: string }[] = [
    {
      icon: icons.globe,
      href: tenantConfig?.about.website,
    },
    {
      icon: icons.mailOpen,
      href: tenantConfig?.about.mail
        ? `mailto:${tenantConfig?.about.mail}`
        : undefined,
    },
    {
      icon: icons.logoFacebook,
      href: tenantConfig?.socialMedia.facebook,
    },
    {
      icon: icons.logoInstagram,
      href: tenantConfig?.socialMedia.instagram,
    },
    {
      icon: icons.logoTiktok,
      href: tenantConfig?.socialMedia.tiktok,
    },
    {
      icon: icons.logoYoutube,
      href: tenantConfig?.socialMedia.youTube,
    },
    {
      icon: icons.logoPinterest,
      href: tenantConfig?.socialMedia.pinterest,
    },
  ]

  if (links.filter((link) => link.href).length === 0) {
    return <></>
  }

  return (
    <>
      <div
        className={css`
          padding: 8px 16px 0;
        `}
      >
        <div
          className={css`
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            justify-items: start;
            align-items: center;
          `}
        >
          {links.map(({ icon, href }) => {
            return href ? (
              <IonButton
                href={href}
                target="_blank"
                fill="clear"
                class="ion-no-padding"
              >
                <IonIcon icon={icon} slot="icon-only" />
              </IonButton>
            ) : (
              <></>
            )
          })}
        </div>
      </div>
    </>
  )
}
